@import "./variables.scss";

body {
  font-family: "Work Sans";
}

.main {
  min-height: 100vh;
}

.fit-content {
  min-height: calc(100vh - 110px);
}

.text-content {
  max-width: $max-width;
  margin: auto;
  padding: 5rem 0;
  min-height: calc(100vh - 110px);
  h1 {
    margin: 0;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }
  table {
    margin-bottom: 1rem;
    thead {
      tr {
        th {
          width: 25%;
          border: 1px solid #000;
          padding: 0.5rem;
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #000;
          padding: 0.5rem;
        }
      }
    }
  }
  &__item {
    margin-top: 2rem;
    h3 {
      margin: 0;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
  &__sub-item {
    margin-top: 2rem;
    h3 {
      margin: 0;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
}

.ant-message-notice {
  &.ant-message-notice-success {
    .ant-message-notice-content {
      background: $green;
      .ant-message-custom-content {
        span {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: $max-width) {
  .text-content {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@import "./header";
@import "./footer";
@import "./home";
@import "./contact-us";
