footer {
  background: #000;
  width: 100%;
  height: 3.125rem;
  padding: 1rem;
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $max-width;
    margin: auto;
    height: 100%;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      gap: 1rem;
      li {
        a {
          color: #fff;
          text-decoration: none;
          font-size: 0.9rem;
          &:hover {
            text-decoration: underline;
            text-underline-offset: 3px;
          }
        }
      }
    }
    p {
      color: #fff;
      margin: 0;
      color: #fff;
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: $mobile-screen) {
  footer {
    height: auto;
    .footer-content {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }
    }
  }
}
